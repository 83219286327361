import showdown from "showdown";
import { useSessionContext } from "./context";
const converter = new showdown.Converter({
  openLinksInNewWindow: true,
});

export const useShowdown = (text) => {
  const { session } = useSessionContext();
  const newText = text.replace("{{ sessionID }}", session);
  const html = converter.makeHtml(newText);
  return html;
};
