import React, { useState } from "react";
import { useSessionContext, useShowdown } from "../../utils";
import Card from "./Card";
import Button from "../ui/Button";

const DisclaimerCard = ({ instructions, completionCheck, clickButton }) => {
  const { session } = useSessionContext();
  const [complete, setComplete] = useState(false);

  const htmlInstructions = useShowdown(instructions);

  const logComplete = () => {
    setComplete(true);
    navigator.clipboard.writeText(session);
  };

  return (
    <Card claimName="disclaimer_card">
      <div className="disclaimer">
        {completionCheck && (
          <div>
            <h2>{completionCheck}</h2>
            <Button
              variant="primary"
              onClick={logComplete}
              logId="click_to_complete"
            >
              {clickButton}
            </Button>
          </div>
        )}
        {complete && (
          <p dangerouslySetInnerHTML={{ __html: htmlInstructions }} />
        )}
      </div>
    </Card>
  );
};

export default DisclaimerCard;
