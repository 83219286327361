import React from "react";
import { Helmet } from "react-helmet";
import { useSessionContext } from "../../utils";

const SiteMetadata = ({ description, image, title }) => {
  const { config } = useSessionContext();
  return (
    <Helmet defer={false} defaultTitle={config.title} title={config.title}>
      <title>{config.title}</title>
      <html lang={process.env.REACT_APP_LANGUAGE} />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <meta name="description" content={config.description} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={process.env.REACT_APP_LANGUAGE} />
      <meta property="og:site_name" content={config.title} />
      <meta property="og:description" content={config.title} />
      <meta property="twitter:description" content={config.description} />
    </Helmet>
  );
};

export default SiteMetadata;
