import React, { useState } from "react";
import { useSessionContext, useShowdown } from "../../utils";
import Button from "../ui/Button";

const PostDetails = ({ data }) => {
  const readMore = useShowdown(data.readMore);
  const description = useShowdown(data.description);
  const { config } = useSessionContext();
  const [showMore, setShowMore] = useState(false);

  const readMoreToggle = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="detail-container">
      {description && (
        <div
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        ></div>
      )}
      {readMore && (
        <div className="s-readmore">
          <Button
            variant="link"
            logId={`read_more_${data.slug}`}
            onClick={readMoreToggle}
          >
            {showMore ? config.readLess : config.readMore}
          </Button>
          <div
            className={`s-readmore__content ${showMore ? "out" : ""}`}
            aria-hidden={!showMore ? "true" : "false"}
            dangerouslySetInnerHTML={{
              __html: readMore,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default PostDetails;
