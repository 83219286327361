import React from "react";
import { Footer } from "../index";
import { useSessionContext, themes } from "../../utils";

const Layout = ({ children, footer }) => {
  const { config } = useSessionContext();
  const grey = config.theme === themes.grey;
  return (
    <div className={`container ${grey ? "grey" : ""}`}>
      {children}
      <Footer footer={footer} />
    </div>
  );
};

export default Layout;
