import React from "react";
import { useShowdown } from "../../utils/hooks/useShowdown";
import Card from "./Card";

const InstructionsCard = ({ instructions, title }) => {
  const htmlInstructions = useShowdown(instructions);

  return (
    <Card claimName="instructions_card">
      <div className="disclaimer">
        {title && <h2>{title}</h2>}

        <p dangerouslySetInnerHTML={{ __html: htmlInstructions }} />
      </div>
    </Card>
  );
};

export default InstructionsCard;
