import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useSessionContext, logScroll, themes } from "../../utils";

const Card = ({ children, claimName }) => {
  const { ref, inView } = useInView({ threshold: 0.3 });
  const { session, step, setStep, startTime, config } = useSessionContext();
  const grey = config.theme === themes.grey;

  useEffect(() => {
    if (inView && session) {
      logScroll(session, claimName, step, startTime).then((res) => {
        if (res) {
          setStep(() => res);
        }
      });
    }
  }, [inView, session]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div ref={ref} className={`card ${grey ? "grey" : ""}`}>
      {children}
    </div>
  );
};

export default Card;
