import React, { useEffect } from "react";
import {
  PostArray,
  DisclaimerCard,
  InstructionsCard,
  SiteMetadata,
  Layout,
} from "../components";
import { useSessionContext, initLogger } from "../utils";

const Home = ({ data }) => {
  const { config, blog } = useSessionContext();
  const { setSession, setStartTime, setTgmSession } = useSessionContext();

  useEffect(() => {
    initLogger(config.surveyName).then((res) => {
      setSession(res.session);
      setStartTime(res.localTime);
    });
    //
  }, [config, setSession, setStartTime]);

  useEffect(() => {
    const { surveyCompany } = config;
    if (surveyCompany && surveyCompany === "TGM") {
      const queryString = window.location.search;
      const params = new URLSearchParams(queryString);
      const value = params.get("sessionID");
      if (value !== undefined) {
        setTgmSession(value);
      }
    }
  }, [setTgmSession, config]);
  return (
    <Layout footer={config.footer}>
      <SiteMetadata />
      <div className="header">
        <h1>{config.title}</h1>
        <h2>{config.tagline}</h2>
      </div>
      <InstructionsCard
        title={config.instructionsTitle}
        instructions={config.instructions}
      />
      <PostArray nodes={blog} />
      {config.endInstructions && (
        <DisclaimerCard
          clickButton={config.formButton}
          instructions={config.endInstructions}
          completionCheck={config.completionCheck}
        />
      )}
    </Layout>
  );
};

export default Home;
