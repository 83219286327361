import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  doc,
  addDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import config from "./node/config.json";

initializeApp({
  apiKey: process.env.REACT_APP_FIRESTORE_APIKEY,
  projectId: process.env.REACT_APP_FIRESTORE_PROJECT,
});

const db = getFirestore();

export const initLogger = async (surveyName) => {
  try {
    const sessionKey = localStorage.getItem("sessionKey");
    let session;
    if (!sessionKey) {
      const docRef = await addDoc(collection(db, "sessions"), {
        survey_name: surveyName,
        version: window.location.hash.replace("#", ""),
        language: document.documentElement.lang,
        url: window.location.host + window.location.pathname,
        created: serverTimestamp(),
      });
      session = docRef.id;
      localStorage.setItem("sessionKey", session);
    } else {
      logReturn(sessionKey);
      session = sessionKey;
    }

    const localTime = new Date();
    return { session, localTime };
  } catch (error) {
    console.log(error);
  }
};

const logReturn = async (session) => {
  // TODO: start time ??
  const docRef = collection(db, "sessions", session, "events");
  await addDoc(docRef, {
    event_type: "return",
    location: "home",
    step_counter: 0,
  });
};

export const logScroll = async (session, entry, step, startTime) => {
  try {
    const docRef = collection(db, "sessions", session, "events");
    await addDoc(docRef, {
      event_type: "scroll",
      location: "home",
      question_name: entry ? entry : "",
      step_counter: step,
    });
    await updateDoc(doc(db, "sessions", session), {
      duration_in_seconds: new Date() - startTime,
    });
    const newStep = step + 1;
    return newStep;
  } catch (error) {
    console.log(error);
    if (error) {
      localStorage.removeItem("sessionKey");
      initLogger(config.surveyName);
    }
  }
};

export const logClickEvent = async (session, id) => {
  try {
    const docRef = collection(db, "sessions", session, "events");
    await addDoc(docRef, {
      event_type: "button_click",
      target: id,
    });
  } catch (error) {
    console.log(error);
  }
};

export const logComplete = async (session) => {
  try {
    const docRef = collection(db, "sessions", session, "events");
    await addDoc(docRef, {
      event_type: "submitted_survey",
      target: "TGM",
    });
  } catch (error) {
    console.log(error);
  }
};
