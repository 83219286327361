import React, { createContext, useContext, useState } from "react";
import configJson from "../node/config.json";
import blogJson from "../node/blog.json";

export const TheContext = createContext({
  session: undefined,
  setSession: () => {},
  startTime: undefined,
  setStartTime: () => {},
  step: undefined,
  setStep: () => {},
  config: undefined,
  setConfig: () => {},
});

export const TheProvider = ({ children }) => {
  const [session, setSession] = useState(undefined);
  const [step, setStep] = useState(0);
  const [startTime, setStartTime] = useState(undefined);
  const [tgmSession, setTgmSession] = useState(undefined);
  const [config, setConfig] = useState(configJson);
  const [blog, setBlog] = useState(blogJson);

  return (
    <TheContext.Provider
      value={{
        session: session,
        setSession: setSession,
        startTime: startTime,
        setStartTime: setStartTime,
        step: step,
        setStep: setStep,
        tgmSession: tgmSession,
        setTgmSession: setTgmSession,
        config: config,
        blog: blog,
      }}
    >
      {children}
    </TheContext.Provider>
  );
};

export const useSessionContext = () => useContext(TheContext);
