import React from "react";

const PostImage = ({ image, text, fontColor }) => {
  return (
    <div
      className="image-card"
      style={{ backgroundImage: `url(${image.url})` }}
    >
      <div className="image-text" style={{ color: fontColor || "black" }}>
        {text}
      </div>
    </div>
  );
};

export default PostImage;
