import React from "react";
import { logClickEvent, useSessionContext } from "../../utils";
import "./Button.css";

export const buttonVariants = {
  primary: "btn primary",
  withIcon: "btn with-icon",
  link: "link",
};

const Button = ({ children, variant, onClick, logId }) => {
  const { session } = useSessionContext();

  const clickHandler = async () => {
    if (logId) {
      await logClickEvent(session, logId);
    }
    onClick();
  };

  return (
    <button className={buttonVariants[variant]} onClick={clickHandler}>
      {children}
    </button>
  );
};

export default Button;
