import React from "react";
// import { Card, User, ImageCard, DetailCard } from "../..";
import Card from "../cards/Card";
import User from "./User";
import PostImage from "./PostImage";
import PostDetails from "./PostDetails";
import { useSessionContext, themes } from "../../utils";

const PostCard = ({ data, person }) => {
  const { config } = useSessionContext();
  const greyTheme = config.theme === themes.grey;
  const backgroundColor = greyTheme ? "#f5f5f5" : data.backgroundColor;
  const color = greyTheme ? "#333333" : data.fontColor;

  return (
    <Card claimName={data.slug}>
      {person && <User person={person} />}
      {data.image && data.image.length ? (
        <PostImage
          image={data.image[0]}
          text={data.claim}
          fontColor={data.fontColor}
        />
      ) : (
        <div
          className={`claim__box ${greyTheme ? "grey" : ""}`}
          style={{
            backgroundColor,
            color,
          }}
        >
          <h1>{data.claim}</h1>
        </div>
      )}
      {(data.description || data.readMore) && <PostDetails data={data} />}
    </Card>
  );
};

export default PostCard;
