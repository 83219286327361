import React, { useEffect } from "react";
import { useShowdown } from "../../utils";

const Footer = ({ footer }) => {
  const footerHTML = useShowdown(footer);

  useEffect(() => {
    const footerRef = document.getElementById("footer");
    footerRef.innerHTML = footerHTML;
  }, [footerHTML]);
  return <footer id="footer" />;
};

export default Footer;
