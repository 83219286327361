import React, { useEffect, useState } from "react";
// import { Avatar } from "@mui/material"
import dayjs from "dayjs";

const User = ({ person }) => {
  const [date, setDate] = useState("");
  const { Name, Picture } = person.node.data;

  useEffect(() => {
    const now = dayjs()
      .subtract(Math.random() * 7, "day")
      .format("MMM D, YYYY");
    setDate(now);
  }, [person]);

  return (
    <div className="user-container">
      <div>
        <img alt={Name} src={Picture[0].url} />
      </div>
      <div className="user-info">
        <h1>{Name}</h1>
        <div>{date}</div>
      </div>
    </div>
  );
};

export default User;
