import React, { useEffect, useState } from "react";
import PostCard from "./PostCard";

const PostArray = ({ nodes, people }) => {
  const [posts, setPosts] = useState(undefined);
  useEffect(() => {
    const randomPosts = nodes.sort(() => (Math.random() > 0.5 ? 1 : -1));
    setPosts(randomPosts);
  }, [nodes]);

  const rand = () => Math.floor(Math.random() * people.length);

  return posts ? (
    <div>
      {posts.map((item, i) => (
        <div key={`card-${item.slug}`}>
          <PostCard data={item} person={people ? people[rand()] : null} />
        </div>
      ))}
    </div>
  ) : (
    <div></div>
  );
};

export default PostArray;
